import React from "react";

import { graphql } from "gatsby";

import Blog from "../../components/pages/Blog";

const BlogIndexPage = (props) => {
    return (
        <Blog
            blogs={props.data.blogs.nodes}
            headline={props.data.page.headline}
            elements={props.data.page.elements}
            loadCount={parseInt(props.data.page.loadCount)}
            startCount={parseInt(props.data.page.startCount)}
        />
    );
};
export default BlogIndexPage;

export const query = graphql`
    query {
        blogs: allBlogsYaml(sort: { fields: datePublished, order: DESC }) {
            nodes {
                slug
                id
                articleBody
                abstract
                dateCreated
                dateModified
                datePublished
                disabled
                section
                title
                yamlId
                video {
                    url
                    thumbnail {
                        alt
                        imageObject {
                            caption
                            description
                        }
                        src {
                            ...ImageSrc
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH

                                    placeholder: BLURRED
                                )
                            }
                        }
                        title
                    }
                }
                image {
                    alt
                    imageObject {
                        caption
                        description
                    }
                    src {
                        ...ImageSrc
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH

                                placeholder: BLURRED
                            )
                        }
                    }
                    title
                }
            }
        }
        page: pagesYaml(templateKey: { eq: "pages/blog" }) {
            ...Page
            startCount
            loadCount
            headline
            elements {
                ...EntireElement
            }
        }
    }
`;
