import React, { useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Elements from "../../shared/Elements";
import ItemCard from "../../shared/ItemCard";
import Button from "../../shared/NewForms/Button";
import NewHeadline from "../../shared/NewHeadline";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    margin: 50px 0;
    ${({ theme }) => css`
        ${theme.paddings.containerOuter.right}
        ${theme.paddings.containerOuter.left}
    `}
`;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 100px;
    row-gap: 50px;
    justify-content: space-between;
    ${({ theme }) => theme.breakpoints.from.small.css`
       grid-template-columns: repeat(2, 1fr);
    `}
    ${({ theme }) => theme.breakpoints.from.medium.css`
       grid-template-columns: repeat(3, 1fr);
    `}
    ${({ theme }) => theme.breakpoints.from.normal.css`
       grid-template-columns: repeat(4, 1fr);
    `}
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: center;
`;

const BlogPage = ({
    blogs,
    headline = "Azubiblog",
    elements,
    startCount = 4,
    loadCount = 4,
}) => {
    const [itemsCount, setItemsCount] = useState(startCount);
    const [loadedBlogs, setLoadedBlogs] = useState(blogs.slice(0, itemsCount));

    const loadMore = () => {
        setItemsCount(itemsCount + loadCount);
        setLoadedBlogs(blogs.slice(0, itemsCount + loadCount));
    };

    return (
        <Container>
            <NewHeadline is={1}>{headline}</NewHeadline>

            <List>
                {loadedBlogs.map((blog) => (
                    <ItemCard key={blog.id} item={blog} />
                ))}
            </List>

            {blogs.length > startCount && loadedBlogs.length < blogs.length && (
                <ActionRow>
                    <Button color="redOutline" onClick={loadMore}>
                        Weitere laden
                    </Button>
                </ActionRow>
            )}
            <Elements elements={elements} />
        </Container>
    );
};

export default BlogPage;
